<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">DAFTAR PENCAPAIAN MAHASISWA</p>
        </header>
        <div class="card-content table-card-content">
          <b-field position="is-right" class="filter-field" grouped group-multiline>
            <generic-filter-select
              class="filter3"
              apiPath="/programstudi/angkatan/options/"
              field="angkatan"
              v-model="filterMap.angkatan"
            ></generic-filter-select>
            <generic-filter-select
              class="filter3"
              apiPath="/stase/options/"
              field="stase"
              v-model="filterMap.stase"
            ></generic-filter-select>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small v-if="pencapaianList.length > 0" class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{
                pagination.startRowNum + pencapaianList.length - 1
              }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="pencapaian in pencapaianList"
              :key="pencapaian.id"
              :title="pencapaian.nama"
              :subtitle1="pencapaian.user"
              :subtitle2="pencapaian.no_hp"
              :detailUrl="{ name: 'pencapaian-pspd-detail', params: { id: pencapaian.id } }"
            ></box>
            <box v-if="pencapaianList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>
          <!-- <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="pencapaianList"
            :mobile-cards="false"
          >
            <b-table-column label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column field="nama" label="Nama" v-slot="props">
              <router-link
                :to="{ name: 'pencapaian-pspd-detail', params: { id: props.row.id } }"
              >{{ props.row.nama }}</router-link>
            </b-table-column>
            <b-table-column field="no_hp" label="No Hp" v-slot="props">{{ props.row.no_hp }}</b-table-column>
            <b-table-column field="user" label="Email" v-slot="props">{{ props.row.user }}</b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>-->

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import PencapaianList from "../models/pencapaianList.js";

export default {
  name: "PencapaianPSPDList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
  },
  data() {
    this.pencapaianListMdl = new PencapaianList();
    this.listMdl = this.pencapaianListMdl; // alias untuk digunakan di paginationmixin
    return this.pencapaianListMdl.getObservables();
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin, paginationMixin],
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";
</style>
